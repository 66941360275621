<template>
    <div class="box">
        <input
            v-for="(item, index) in value"
            :key="index"
            class="input_range"
            type="range"
            :min="min"
            :max="max"
            :step="step"
            v-model="value[index]"
            @input="updateValue( $event, index )"
        />

        <div class="range-box">
            <div class="range"></div>
        </div>

        <ul>
            <li
                v-for="(item, index) in value"
                class="tip"
                :style="{ 'left': floatPos(item,index) }"
                :key="index"
            >
                <span class="tit">第 {{index+1}} 阶段酬金</span>
                <div class="val">{{showVal(item,index)}}</div>
            </li>

            <li
                v-for="(item, index) in value"
                class="tip2"
                :style="{ 'left': floatPos2(item,index) }"
                :key="index+10"
            >
                <span class="item item1">总酬金：{{max}}</span>
                <span class="item item2">占比：{{showPercent(item,index)}}%</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "slider",
    props: ['value', 'min', 'max', 'step'],
    computed: {
        isRange: function isRange() {
            return this.range;
        },
        fixedMin: function fixedMin() {
            return this.fixValue(this.min);
        },
        fixedMax: function fixedMax() {
            return this.fixValue(this.max);
        },
        rangeFraction: function rangeFraction() {
            return 100 / (this.fixedMax - this.fixedMin);
        }
    },
    methods: {
        fixValue: function fixValue(value) {
            if (this.value[0] >= this.value[1]) {
                return;
            }
            if (this.value[1] <= this.value[0] || this.value[1] > this.value[2]) {
                return;
            }
            if (this.value[2] <= this.value[1]) {
                return;
            }
            return parseFloat(parseFloat(value).toFixed(1));
        },

        //顶部浮动提示文字位置
        floatPos: function floatPos(value) {
            return (-(this.fixedMin - value) * this.rangeFraction - 2) + "%";
        },


        //顶部浮动提示文字位置
        floatPos2: function floatPos(value) {
            return (-(this.fixedMin - value) * this.rangeFraction + 1) + "%";
        },

        //显示酬金数
        showVal(val, index) {
            if (index > 0) {
                return val - parseInt(this.value[index - 1]);
            } else {
                return val;
            }
        },

        showPercent(val, index) {
            if (index > 0) {
                return (parseFloat((val - this.value[index - 1]) / this.max * 100)).toFixed(2);
            } else {
                return (parseFloat(val / this.max * 100)).toFixed(2);
            }
        },

        //滑动更新数据
        updateValue: function updateValue(ev, index) {
            this.$set(this.value, index, ev.target.value);
            let val = ev.target.value;
            this.$set(this.value, this.value.length - 1, parseInt(this.max));//最后一段不可改

            //非最后一个，每个阶段值不能小于总金额的10%
            if (this.value.length >= 2) {
                //第二阶段滑块
                if (index == 1) {
                    if (val <= parseInt(this.value[0]) + parseInt(this.max * 0.1)) {//非最后一个，每个阶段值不能小于总金额的10%
                        this.$set(this.value, 1, parseInt(this.value[0]) + parseInt(this.max * 0.1));
                    }
                } else {
                    if (index != (this.value.length - 1) && val <= parseInt(this.value[index - 1]) + (this.max * 0.1)) {
                        this.$set(this.value, index, parseInt(this.value[index - 1]) + parseInt(this.max * 0.1));
                    }
                }
            }

            //三个及以上，往右，不超过（下一个-10%）
            if (this.value.length > 2) {
                if (index < this.value.length - 2) {
                    if (val > parseInt(this.value[index + 1]) - this.max * 0.1) {
                        this.$set(this.value, index, parseInt(this.value[index + 1]) - this.max * 0.1);
                    }
                }
            }

            //第一阶段不能小于总酬金10%
            if (index == 0) {
                if (val <= parseInt(this.max * 0.1)) {
                    this.$set(this.value, 0, parseInt(this.max / 10));
                }
            }

            //倒数第二阶段不能少于总酬金10%
            if (index == this.value.length - 2) {
                if (val > parseInt(this.max * 0.9)) {
                    this.$set(this.value, this.value.length - 2, this.max * 0.9);
                }
            }
        },

        //初始化
        init() {
            this.$set(this.value, this.value.length - 1, parseInt(this.max));//第二段不可改
        }
    },

    created() {
        this.init();
    }
};
</script>

<style lang='less' scoped>
/**input:range */
.box {
    position: relative;
    margin: 30px 0;
    min-height: 40px;
    color: #8e9cad;
    font-size: 12px;
}

.input_range {
    -webkit-appearance: none; //去除默认样式
    -moz-appearance: none; //去除默认样式
    appearance: none; //去除默认样式
    width: 100%;
    height: 0px; //让多个range重合时候也可以拖动
    background: red;
    position: absolute; //让多个range合成一个
    z-index: 2;
    cursor: pointer;
}

.range-box {
    position: absolute;
    top: -6px;
    z-index: 1;
    width: 100%;
    height: 18px;
    background: linear-gradient(#c9cbcd, #edede9);
    padding: 4px;
    border-radius: 9px;
    .range {
        width: 100%;
        height: 10px;
        background-image: linear-gradient(
            0deg,
            rgb(68, 108, 208) 0%,
            rgb(119, 154, 242) 100%
        );
        border-radius: 5px;
    }
}

/**chrome - 滑动条 */
.input_range::-webkit-slider-runnable-track {
    width: 100%;
    -webkit-appearance: none;
    position: relative;
    top: 0px;
    height: 0px;
}
/**chrome - 圆点 */
.input_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    top: -15px;
    width: 30px;
    height: 30px;
    background-image: linear-gradient(0deg, #ccc, #fff 100%);
    border: 4px solid rgb(104, 138, 225);
    border-radius: 50%;
    box-shadow: 0px 2px 0px #d8dbde;
}

/**火狐 - 滑动条 */
.input_range::-moz-range-track {
    -moz-appearance: none;
    position: absolute;
    height: 0px;
}
/**火狐 - 圆点 */
.input_range::-moz-range-thumb {
    -moz-appearance: none;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    background-image: linear-gradient(0deg, #ccc, #fff 100%);
    border: 4px solid rgb(104, 138, 225);
    border-radius: 50%;
    box-shadow: 0px 2px 0px #d8dbde;
}

/**IE - 滑动条 */
.input_range::-ms-track {
    -ms-appearance: none;
    position: absolute;
    height: 0px;
}
/**IE - 圆点 */
.input_range::-ms-thumb {
    -ms-appearance: none;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    background-image: linear-gradient(0deg, #ccc, #fff 100%);
    border: 4px solid rgb(104, 138, 225);
    border-radius: 50%;
    box-shadow: 0px 2px 0px #d8dbde;
}

.input_range::-moz-focus-outer {
    border: 0; //去除firefox边框外虚线
}

.input_range:focus,
.input_range:focus::-webkit-slider-runnable-track {
    outline: none;
    outline: 0;
    border: 0;
}

/**上标 */
.tip {
    position: relative;
    list-style: none;
    position: absolute;
    top: -80px;
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
    height: 60px;
    line-height: 30px;
    text-align: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    font-size: 14px;
    font-weight: normal;
    background-image: linear-gradient(
        -90deg,
        rgb(68, 108, 208) 0%,
        rgb(94, 132, 228) 100%
    );
    font-weight: 100;
    font-size: 12px;
    box-shadow: 0px 5px 10px #ccc;
    border-radius: 100px;
    width: 100px;
    color: #fff;
    padding-top: 4px;
    .val {
        font-weight: normal;
        font-size: 16px;
        line-height: 15px;
    }
}
/**下面百分比*/
.tip2 {
    position: relative;
    list-style: none;
    position: absolute;
    top: 20px;
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
    height: 20px;
    line-height: 30px;
    text-align: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    font-size: 12px;
    font-weight: normal;
    color: #6086f5;
    padding-top: 4px;
    width: 120px;
    text-align: left;
    .val {
        font-weight: normal;
        font-size: 16px;
        line-height: 15px;
    }
    .item {
        display: inline-block;
        margin: 0;
        padding: 0;
        position: absolute;
    }
    .item1 {
        top: 0px;
    }
    .item2 {
        top: 16px;
    }
}
</style>