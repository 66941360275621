<template>
    <div class="todo-panel todo-pannel-adjust" v-loading="loading">
        <div class="white-bg">
            <div class="invit-tit">
                <h3 class="tit">
                    <span class="line">发送邀请</span>
                </h3>
            </div>

            <div class="twait-box">
                <div class="twait-nath1">
                    <div class="twait-bd invit-bd">
                        <!-- ------------项目&极客信息------- -->
                        <div class="amount-wraper">
                            <div class="amount-left">
                                <div class="amount-inner">
                                    <h4 class="task-name">项目信息</h4>
                                    <ul class="tinfor-list">
                                        <li class="tinfor-item">
                                            <span class="lab-name">项目名称：</span>
                                            <p class="todo-tit-txt">{{task.task_name}}</p>
                                            <div class="btns-wraper">
                                                <el-button
                                                    size="mini"
                                                    @click="goTaskInfo(task.tk)"
                                                >项目详情</el-button>
                                            </div>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">项目编号：</span>
                                            <p class="todo-tit-txt" v-text="task.sn"></p>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">项目模式：</span>
                                            <p class="todo-tit-txt" v-text="task.task_type_value"></p>
                                        </li>
                                        <!--
                                        <li class="tinfor-item">
                                            <span class="lab-name">履约保证金情况：</span>
                                            <p
                                                class="todo-tit-txt"
                                            >{{task.is_bond==1?'需要托管':'不需要托管'}}</p>
                                        </li>
                                        <li class="tinfor-item" v-if="task.is_bond==1">
                                            <span class="lab-name">履约保证金金额：</span>
                                            <p class="todo-tit-txt">￥{{task.bond}}元</p>
                                        </li>
                                        -->
                                    </ul>
                                </div>
                            </div>

                            <div class="amount-right" v-if="task.enroll">
                                <div class="amount-inner">
                                    <h4 class="task-name">极客信息</h4>
                                    <ul class="tinfor-list">
                                        <li
                                            class="tinfor-item tinfor-item1"
                                            v-if="task.enroll.geek"
                                        >
                                            <div class="btns-wraper">
                                                <el-button
                                                    size="mini"
                                                    @click="gotoGeekDetail(task.enroll.geek.uk)"
                                                >极客详情</el-button>
                                                <el-button
                                                    size="mini"
                                                    @click="send_message(task.enroll.geek.uk)"
                                                >发送消息</el-button>
                                            </div>
                                            <span class="pic-box">
                                                <img :src="util.reImg(task.enroll.geek.avatar)" />
                                            </span>
                                            <div class="tinfor-cot">
                                                <h4>{{task.enroll.geek.name}}</h4>
                                                <p>{{task.enroll.geek.area_value}}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="lab-name">身份类型：</span>
                                            <p
                                                class="todo-tit-txt"
                                            >{{task.enroll.geek.attestation_type_value}}工程极客</p>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name" style="font-size:14px;">信用评分：</span>
                                            <p class="todo-tit-txt">
                                                <el-rate
                                                    :value="parseFloat(task.enroll.geek.credence)"
                                                    disabled
                                                    disabled-void-color="#ebebeb"
                                                    show-score
                                                    text-color="#999"
                                                ></el-rate>
                                            </p>
                                        </li>
                                        <li class="tinfor-item">
                                            <template v-if="task.task_type==3">
                                                <span class="lab-name" style="font-size:14px;">项目报价：</span>
                                                <span
                                                    class="todo-tit-txt"
                                                    style="font-size:14px;"
                                                >￥{{task.enroll.price}}元</span>
                                                <span
                                                    class="lab-name"
                                                    style="margin-left:30px;font-size:14px;"
                                                    v-if="task.task_type==3"
                                                >工作天数：</span>
                                                <span
                                                    class="todo-tit-txt"
                                                    style="font-size:14px;"
                                                >{{task.enroll.work_time}}天</span>
                                            </template>

                                            <template v-if="task.task_type!=3">
                                                <span class="lab-name" style="font-size:14px;">项目报价：</span>
                                                <span
                                                    class="todo-tit-txt"
                                                    style="font-size:14px;"
                                                >￥{{task.enroll.price}}元</span>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!--------------邀请信息--------->
                        <div class="setrem-wrap">
                            <div class="setrem-bd" id="invite_info">
                                <div class="setrem">
                                    <el-form ref="formRules" :rules="formRules" :model="form">
                                        <!---果最终交付时间---->
                                        <el-form-item>
                                            <p class="v-big-tit">
                                                <i class="jk-icon-xuanzeriqi"></i>项目要求
                                                <span class="jk-icon-wenhao"></span>
                                            </p>
                                        </el-form-item>

                                        <el-form-item
                                            label="项目开始时间"
                                            prop="work_start_time"
                                            :rules="[{required: true,message: '请选择项目开始时间',trigger: ['change', 'blur']}]"
                                        >
                                            <el-date-picker
                                                v-model="form.work_start_time"
                                                type="datetime"
                                                placeholder="选择日期时间"
                                                default-time="00:00:00"
                                                :picker-options="work_start_time_pickerOptions"
                                                @change="local_save(1)"
                                            ></el-date-picker>
                                        </el-form-item>

                                        <el-form-item
                                            label="项目结束时间"
                                            prop="work_end_time"
                                            :rules="[{required: true,message: '请选择项目结束时间',trigger: ['change', 'blur']}]"
                                        >
                                            <el-date-picker
                                                v-model="form.work_end_time"
                                                type="datetime"
                                                placeholder="选择日期时间"
                                                default-time="23:59:59"
                                                :picker-options="work_end_time_pickerOptions"
                                                @change="local_save(2)"
                                            ></el-date-picker>
                                        </el-form-item>
                                        <div
                                            style="margin-top:0px;margin-bottom:20px;margin-left:10px;font-size:12px;color:#999;"
                                        >请设置您希望工程极客执行项目的开始时间和结束时间。</div>

                                        <el-form-item
                                            label="成果最终交付时间"
                                            prop="delivery_time"
                                            :rules="[{required: true,message: '请选择成果最终交付时间',trigger: ['change', 'blur']}]"
                                        >
                                            <el-date-picker
                                                v-model="form.delivery_time"
                                                type="date"
                                                format="yyyy 年 MM 月 dd 日"
                                                value-format="yyyy-MM-dd"
                                                placeholder="选择日期"
                                                :picker-options="delivery_time_pickerOptions"
                                                @change="local_save"
                                            ></el-date-picker>
                                        </el-form-item>
                                        <!-- <div style="margin-top:0px;margin-left:10px;font-size:12px;color:#999;">请设置您希望工程极客提交工作成果的时间，这个时间不能早于上面设置的“项目结束时间”</div> -->

                                        <div class="height_empty"></div>

                                        <!---项目酬金及支付方式---->
                                        <el-form-item>
                                            <p class="v-big-tit">
                                                <i class="jk-icon-renwuchoujinzhifufangshi"></i>项目酬金
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>

                                        <el-form-item
                                            v-if="task.task_type==1 || task.task_type==2"
                                            label="项目酬金总额"
                                            class="ipt-mony"
                                            prop="remuneration"
                                            :rules="[{required: true,message: '请输入项目酬金总额',trigger: ['change', 'blur']}]"
                                        >
                                            <el-input
                                                placeholder="请填写"
                                                v-model="form.remuneration"
                                                @blur="local_save"
                                                @input="blockCountChange"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>元</span>
                                        </el-form-item>

                                        <!--每天工期-->
                                        <el-form-item
                                            v-if="task.task_type==3"
                                            label="每天酬金"
                                            class="ipt-mony"
                                            prop="remuneration"
                                            :rules="[{required: true,message: '请输入项目酬金',trigger: ['change', 'blur']}]"
                                        >
                                            <el-input
                                                type="number"
                                                placeholder="请填写"
                                                v-model="form.day_price"
                                                @blur="local_save(2)"
                                                @input="blockCountChange"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>元/天</span>
                                        </el-form-item>

                                        <!---项目工期---->
                                        <el-form-item
                                            v-if="task.task_type==3"
                                            label="项目工期"
                                            class="ipt-mony"
                                            prop="work_time"
                                            :rules="[{required: true,message: '请输入项目工期',trigger: ['change', 'blur']}]"
                                        >
                                            <el-input
                                                type="number"
                                                placeholder="请填写"
                                                v-model="form.work_time"
                                                disabled
                                                readonly="readonly"
                                                @blur="local_save"
                                                @change="checkWorkTime"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>天</span>
                                        </el-form-item>

                                        <el-form-item
                                            v-if="task.task_type==3"
                                            label="项目酬金总额"
                                            class="ipt-mony"
                                            prop="remuneration"
                                            :rules="[{required: true,message: '请输入项目酬金',trigger: ['change', 'blur']}]"
                                        >
                                            <el-input
                                                type="number"
                                                placeholder="请填写"
                                                v-model="form.remuneration"
                                                disabled
                                                readonly="readonly"
                                                @blur="local_save"
                                                @input="blockCountChange"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>元</span>
                                        </el-form-item>

                                        <div class="height_empty"></div>

                                        <!---是否托管酬金---->
                                        <el-form-item>
                                            <p class="v-big-tit">
                                                <i class="jk-icon-daibanlvyuejin"></i>是否托管酬金
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>

                                        <el-form-item>
                                            <el-radio-group
                                                v-model="form.is_remuneration"
                                                @change="remunerationChange"
                                            >
                                                <el-radio-button label="0">不托管</el-radio-button>
                                                <el-radio-button label="1">托管</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>

                                        <div class="height_empty"></div>

                                        <!---是否签署电子合同---->
                                        <!-- <el-form-item v-if="form.is_remuneration == 1">
                                            <p class="v-big-tit">
                                                <i class="jk-icon-dianzihetong"></i>是否签署电子合同
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>

                                        <el-form-item v-if="form.is_remuneration == 1">
                                            <el-radio-group
                                                v-model="form.is_contract"
                                                @change="cantractChange"
                                            >
                                                <el-radio-button label="0">不签署</el-radio-button>
                                                <el-radio-button label="1">签署</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>

                                        <div class="height_empty"></div>-->

                                        <!---是否需要对方托管履约保证金---->
                                        <el-form-item v-if="form.is_remuneration==1">
                                            <p class="v-big-tit">
                                                <i class="jk-icon-daibanlvyuejin"></i>是否需要对方托管履约保证金
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>

                                        <el-form-item v-if="form.is_remuneration==1">
                                            <el-radio-group
                                                v-model="form.is_bond"
                                                @change="bondChange"
                                            >
                                                <el-radio-button label="0">不托管</el-radio-button>
                                                <el-radio-button label="1">托管</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>

                                        <el-form-item
                                            label="履约保证金金额"
                                            class="ipt-mony"
                                            prop="bond"
                                            v-if="form.is_remuneration==1 && form.is_bond == 1"
                                            :rules="[{required: true,message: '请输入履约保证金金额',trigger: ['change', 'blur']}]"
                                        >
                                            <el-input
                                                type="number"
                                                v-model="form.bond"
                                                placeholder="请填写"
                                                @blur="local_save"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>元</span>
                                        </el-form-item>

                                        <div class="height_empty"></div>

                                        <!------------------------------------------是否需要分段支付酬金---------------------------------------->
                                        <el-form-item v-if="form.is_remuneration==1">
                                            <p class="v-big-tit">
                                                <i class="jk-icon-daibanlvyuejin"></i>酬金支付方式
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>

                                        <el-form-item v-if="form.is_remuneration==1">
                                            <el-radio-group
                                                v-model="form.is_block"
                                                @change="blockChange"
                                            >
                                                <el-radio-button label="0">一次性支付</el-radio-button>
                                                <el-radio-button label="1">分段支付</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>

                                        <div v-if="form.is_remuneration==1 && form.is_block == 1">
                                            <p style="margin-top:10px;">
                                                <el-form-item
                                                    label="项目分段数"
                                                    class="ipt-mony"
                                                    prop="blockCount"
                                                    :rules="[{validator:blockCountRules ,trigger: ['change', 'blur']},{required: true,message: '请输入项目分段数',trigger: ['change', 'blur']}]"
                                                >
                                                    <el-radio-group
                                                        v-model="form.blockCount"
                                                        size="medium"
                                                        @change="blockCountChange"
                                                    >
                                                        <el-radio-button label="2"></el-radio-button>
                                                        <el-radio-button label="3"></el-radio-button>
                                                        <el-radio-button label="4"></el-radio-button>
                                                        <el-radio-button label="5"></el-radio-button>
                                                    </el-radio-group>

                                                    <el-button-group style="float:right;">
                                                        <el-button
                                                            v-if="form.blockCount>1 && form.blockCount<6"
                                                            style="height:30px;line-height:9px;"
                                                            @click="pfBlockCountChange"
                                                        >平均分配酬金</el-button>
                                                        <el-button
                                                            v-if="form.blockCount>1 && form.blockCount<6"
                                                            style="height:30px;line-height:9px;"
                                                            @click="sfBlockCountChange"
                                                        >最低首付酬金</el-button>
                                                        <el-button
                                                            v-if="form.blockCount>1 && form.blockCount<6"
                                                            style="height:30px;line-height:9px;"
                                                            @click="setPrice"
                                                        >快捷设置阶段酬金</el-button>
                                                    </el-button-group>
                                                </el-form-item>
                                            </p>

                                            <div></div>

                                            <div
                                                class="subsection-box"
                                                v-if="form.blockCount && form.blockCount>1 && form.blockCount<6"
                                            >
                                                <el-table :data="form.block" style="width: 100%">
                                                    <el-table-column prop="title" label="项目阶段名称">
                                                        <template slot-scope="scope">
                                                            <el-tooltip
                                                                class="item"
                                                                effect="light"
                                                                content="项目阶段名称不能为空"
                                                                placement="right"
                                                                :manual="true"
                                                                :value="!scope.row.title"
                                                            >
                                                                <el-input
                                                                    size="mini"
                                                                    placeholder="请输入阶段名称"
                                                                    v-model="scope.row.title"
                                                                    @blur="local_save"
                                                                ></el-input>
                                                            </el-tooltip>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column label="项目阶段酬金（元）">
                                                        <template slot-scope="scope">
                                                            <el-tooltip
                                                                class="item"
                                                                effect="light"
                                                                content="第一阶段酬金不能少于总酬金的10%，不能超过项目总酬金"
                                                                placement="right"
                                                                v-if="scope.$index==0?true:false"
                                                                :manual="true"
                                                                :value="firstPriceTip"
                                                            >
                                                                <el-input
                                                                    type="number"
                                                                    size="mini"
                                                                    placeholder="请输入"
                                                                    v-model="scope.row.remuneration"
                                                                    @input="priceInput(scope.row.remuneration,scope.$index)"
                                                                ></el-input>
                                                            </el-tooltip>

                                                            <el-tooltip
                                                                class="item"
                                                                effect="light"
                                                                content="阶段酬金输入不正确，除最后一阶段外，其他阶段酬金不能低于总酬金的10%"
                                                                placement="right"
                                                                :manual="true"
                                                                :value="form.blockCount>=3 && (parseFloat(form.remuneration/10) > parseFloat(scope.row.remuneration)) && scope.$index!= form.block.length-1"
                                                                v-else
                                                            >
                                                                <el-input
                                                                    type="number"
                                                                    size="mini"
                                                                    placeholder="请输入"
                                                                    v-model="scope.row.remuneration"
                                                                    @input="priceInput(scope.row.remuneration,scope.$index)"
                                                                    :disabled="form.blockCount==2"
                                                                ></el-input>
                                                            </el-tooltip>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column
                                                        prop="done_time"
                                                        label="项目阶段预计完成时间"
                                                    >
                                                        <template slot-scope="scope">
                                                            <el-tooltip
                                                                class="item"
                                                                effect="light"
                                                                content="项目阶段预计完成时间不能为空"
                                                                placement="right"
                                                                :manual="true"
                                                                :value="!scope.row.done_time"
                                                            >
                                                                <el-tooltip
                                                                    class="item"
                                                                    effect="light"
                                                                    content="项目阶段预计完成时间必须大于上一阶段时间"
                                                                    placement="right"
                                                                    :manual="true"
                                                                    :value="scope.$index>0 && scope.row.done_time<=form.block[scope.$index-1]['done_time']"
                                                                >
                                                                    <el-date-picker
                                                                        size="mini"
                                                                        v-model="scope.row.done_time"
                                                                        type="date"
                                                                        format="yyyy 年 MM 月 dd 日"
                                                                        value-format="yyyy-MM-dd"
                                                                        placeholder="选择日期"
                                                                        :picker-options="pickerOptions1"
                                                                        @change="local_save"
                                                                    ></el-date-picker>
                                                                </el-tooltip>
                                                            </el-tooltip>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                        </div>

                                        <el-form-item class="crt-meu">
                                            <el-row style="height:100px;padding-top:40px">
                                                <el-col :span="6">&nbsp;</el-col>
                                                <el-col :span="6">
                                                    <el-button
                                                        class="back"
                                                        @click="$router.back(-1)"
                                                    >返回</el-button>
                                                </el-col>
                                                <el-col :span="6">
                                                    <el-button
                                                        type="primary"
                                                        :disabled="!save_disabled"
                                                        :style="{background:save_disabled==false?'gray':'#587DDC',borderColor:save_disabled==false?'gray':'#587DDC'}"
                                                        @click="save"
                                                    >立即邀请</el-button>
                                                </el-col>
                                            </el-row>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            class="blue-top-border subs-dialog subys-dialog"
            title="分段支付设置"
            :visible.sync="setPriceDialog"
            width="1000px"
            center
            append-to-body
        >
            <div style="padding:0 80px;height:430px;padding-top:80px;">
                <vue-slider :min="0" :max="form.remuneration" :step="1" v-model="priceValue"></vue-slider>
                <div class="content" style="padding-top:20px;">
                    <div style="width:120px;height:100px;float:left;">
                        <img
                            style="height:100px;"
                            :src="websiteConfigs.sourceUrl + '/upload/image/tip.jpg'"
                        />
                    </div>
                    <div style="width:400px;float:left;padding-top:46px;font-size:14px;">
                        <span>
                            您可以左右拖动上方滑条以设定每阶段的酬金数额。
                            <br />请注意，每一阶段的酬金数额不能低于总金额的10%。
                        </span>
                    </div>
                </div>
                <div class="pay_type_class">
                    <el-button @click="savePriceSet">确认设置</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueSlider from "@/components/slider.vue";
import "vue-slider-component/theme/antd.css";
export default {
    name: "taskInvite",
    metaInfo() {
        return {
            title: "发送邀请 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        VueSlider
    },

    computed: {
        save_disabled: function () {
            //保存按钮可以用状态
            let form = this.form;
            if (!form.delivery_time) {
                //未填写成果交付时间
                return false;
            }

            if (!form.work_start_time) {
                //项目开始时间
                return false;
            }

            if (!form.work_end_time) {
                //项目结束时间
                return false;
            }

            if (this.task.task_type == 1) {
                //如果是竞价项目
                if (!form.remuneration) {
                    //未填写酬金
                    return false;
                }
            }

            if (form.is_remuneration != 1) {
                //不需要托管酬金
                return true;
            }

            if (form.is_bond == 1) {
                //需要托管履约保证金
                if (!form.bond) {
                    //保证金为空
                    return false;
                }
            }

            //不分段
            if (form.is_block != 1) {
                return true;
            }

            //项目分阶段支付
            if (form.blockCount > 1 && form.blockCount < 6) {
                if (form.block && form.block.length > 0) {
                    //检查是否每项都填写了，为空则不准提交
                    let total = 0;
                    for (let index = 0; index < form.block.length; index++) {
                        if (
                            !form.block[index]["title"] ||
                            !form.block[index]["remuneration"] ||
                            !form.block[index]["done_time"]
                        ) {
                            return false;
                        }
                        if (
                            !parseFloat(form.block[index]["remuneration"]) ||
                            parseFloat(form.block[index]["remuneration"]) <= 0
                        ) {
                            return false;
                        }

                        //时间判断
                        if (
                            index > 0 &&
                            parseInt(
                                new Date(
                                    form.block[index]["done_time"]
                                ).getTime()
                            ) <=
                            parseInt(
                                new Date(
                                    form.block[index - 1]["done_time"]
                                ).getTime()
                            )
                        ) {
                            return false;
                        }

                        //非最后一阶段，其他阶段价格不能小于总酬金10%
                        if (
                            parseFloat(form.remuneration) / 10 >
                            parseFloat(form.block[index]["remuneration"]) &&
                            index != form.block.length - 1
                        ) {
                            return false;
                        }

                        total += parseFloat(form.block[index]["remuneration"]);
                    }

                    if (parseFloat(total) != parseFloat(form.remuneration)) {
                        return false;
                    }

                    // //第一阶段价格不能小于总酬金的10%,不能超过总酬金
                    // if ((parseFloat(form.remuneration) / 10) > parseFloat(form.block[0]['remuneration']) || parseFloat(form.block[0]['remuneration']) >= parseFloat(form.remuneration)) {
                    //     return false;
                    // }
                }
                return true;
            }
            return false;
        }
    },

    data() {
        var _this = this;
        return {
            form: {
                tk: "",
                delivery_time: "",
                work_start_time: "",
                work_end_time: "",
                remuneration: "",
                work_time: "",
                is_contract: 0,
                is_bond: 0,
                bond: "",
                is_remuneration: 0,
                is_block: 0,
                block: [],
                blockCount: 1, //分段数
                day_price: 0
            },
            priceValue: [],
            setPriceDialog: false, //快捷设置酬金对话框
            firstPriceTip: false, //第一阶段价格输入框提示框状态
            formRules: {},
            task: "", //项目详情
            loading: false,
            work_start_time_pickerOptions: {
                //项目开始
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },

            work_end_time_pickerOptions: {
                //项目结束
                disabledDate(time) {
                    return time.getTime() < new Date(_this.form.work_start_time).getTime();
                }
            },

            delivery_time_pickerOptions: {
                //成果交付时间
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },

            pickerOptions: {
                //成果交付时间
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },

            pickerOptions1: {
                //分段时间选择
                disabledDate(time) {
                    let nowTime = Date.now() + 6 * 24 * 60 * 60 * 1000;
                    return time.getTime() < nowTime;
                }
            }
        };
    },

    methods: {
        checkWorkTime() {
            if (this.form.work_time < 1) {
                this.form.work_time = 1;
                this.$message.error("工期不能低于一天");
            }
        },
        //加载项目详情数据
        loadDetailData() {
            let _this = this;
            _this.post(
                "/task/task/detail_enrolled",
                {
                    tk: _this.$route.query.tk,
                    uk: _this.$route.query.uk
                },
                res => {
                    if (res.code == 200) {
                        _this.task = res.data; //项目信息
                        _this.form.tk = _this.$route.query.tk;
                        _this.form.remuneration = res.data.enroll.price; //极客报名时候的报价
                        _this.form.work_time = res.data.enroll.work_time; //项目工期
                        _this.form.work_start_time = res.data.work_start_time; //项目开始时间
                        _this.form.work_end_time = res.data.work_end_time; //项目结束时间
                        _this.form.day_price = (_this.form.remuneration / _this.form.work_time).toFixed(0);
                        if (res.data.is_bond == 1) {
                            //如果项目发布时候，设置了需要托管履约保证金
                            _this.form.is_contract = 1; //签署电子合同
                            _this.form.is_bond = 1; //需要履约保证金
                            _this.form.bond = res.data.bond; //履约保证金金额
                        }
                        //从缓存获取数据
                        let invite_form_data = sessionStorage.getItem(
                            "invite_form_data"
                        );
                        if (invite_form_data && invite_form_data.tk == _this.$route.query.tk) {
                            _this.form = JSON.parse(invite_form_data);
                        }
                    }
                }
            );
        },

        //项目阶段数验证方法
        blockCountRules(rules, val, callback) {
            if (val < 2 || val > 5) {
                callback(
                    new Error("项目阶段数最少为2个阶段，且最多不超过5个阶段")
                );
            }
            callback();
        },

        //查看项目详情
        goTaskInfo(tk) {
            //存储当前值
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            );
            this.$router.push("/task/detail?view=1&tk=" + tk);
        },

        //查看极客详情
        gotoGeekDetail(uk) {
            //存储当前值
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            );
            this.$router.push("/firm/index?view=1&uk=" + uk);
        },

        //给极客发送消息
        send_message(uk) {
            this.$router.push("/user/message/sendmsg?uk=" + uk);
        },

        //【酬金托管】切换事件
        remunerationChange(val) {
            if (val == 0) {
                this.form.is_bond = 0;
                this.form.bond = "";
                this.form.is_block = 0;
                this.form.block = "";
                this.form.is_remuneration = 0;
            }
            this.local_save();
        },

        //【电子合同】切换事件
        cantractChange(val) {
            this.local_save();
        },

        //【履约保证金】切换事件
        bondChange(val) {
            if (val == 1) {
                this.formRules.bond == true;
            } else {
                this.formRules.bond == false;
            }
            this.local_save();
        },

        //[分段支付]切换事件
        blockChange(val) {
            this.local_save();
            if (this.form.is_block != 1) {
                this.form.blockCount = 1;
                this.form.block = [];
            }
        },

        //提交邀请信息
        save() {
            let _this = this;
            _this.form.uk = _this.$route.query.uk;
            _this.form.tk = _this.$route.query.tk;

            if (new Date(_this.form.delivery_time).getTime() < new Date(_this.form.work_start_time).getTime()) {
                _this.$message.error('成果提交时间应晚于项目开始时间');
                return false;
            }
            if (this.task.task_type == 3){
                
                if (this.form.day_price < 200) {
                    _this.$message.error('每日酬金最低不能小于200');
                    return false;
                }
            }
            _this.$refs["formRules"].validate((valid, obj) => {
                if (valid) {
                    _this.loading = true;
                    _this.post("/task/task/invite", _this.form, res => {
                        _this.loading = false;
                        if (res.code == 200) {
                            sessionStorage.removeItem("invite_form_data");
                            _this.$message.success("发送邀请成功");
                            _this.$router.push(
                                "/user/task/todo?tk=" + _this.$route.query.tk
                            );
                        } else {
                            if (res.msg) {
                                _this.$message.error(res.msg);
                            } else {
                                _this.$message.error("提交失败");
                            }
                        }
                    });
                }
            });
        },

        //缓存表单信息
        local_save(type = 0) {
            if (!this.form.work_start_time && this.form.work_end_time) {
                this.form.work_end_time = '';
                this.$message.error('请先设定项目起始时间');
            }
            switch (type) {
                case 1:
                    this.form.work_end_time = '';
                    break;
                case 2:
                    let diff = new Date(this.form.work_end_time).getTime() - new Date(this.form.work_start_time).getTime();
                    let days = Math.floor(diff / (24 * 3600 * 1000));

                    this.form.work_time = days + 1;
                    if (this.form.work_time > 365) {
                        this.$message.error('日薪任务时间不能超过365天');
                        this.form.work_end_time = '';
                    }
                    if (this.form.day_price < 1) this.form.day_price = 1;
                    this.form.remuneration = this.form.work_time * this.form.day_price;
                    break;
            }

            sessionStorage.setItem(
                "invite_form_data",
                JSON.stringify(this.form)
            );
            // if (this.form.work_start_time) {
            //     this.form.delivery_time = "";
            // }
            // var end_time = new Date(this.form.work_start_time).gettime();
            // var delivery_time = new Date(this.form.delivery_time).gettime();
            // if (delivery_time > end_time) {
            //     this.formRules.delivery_time.$refs.validate =
            //         "成果最终交付时间不能大于项目结束时间";
            // }
        },

        //分段数输入框事件
        blockCountChange() {
            //自动生成分段内容
            let val = this.form.blockCount;
            let new_block = [];
            if (this.task.task_type == 3){
                let diff = new Date(this.form.work_end_time).getTime() - new Date(this.form.work_start_time).getTime();
                let days = Math.floor(diff / (24 * 3600 * 1000));

                this.form.work_time = days + 1;
                if (this.form.work_time > 365) {
                    this.$message.error('日薪任务时间不能超过365天');
                    this.form.work_end_time = '';
                }
                if (this.form.day_price < 1) this.form.day_price = 1;
                this.form.remuneration = this.form.work_time * this.form.day_price;
            }
            //检查金额
            if (parseInt(this.form.remuneration) > 9999999999) {
                this.$message.error('总酬金不能高于9999999999');
                this.form.remuneration = 9999999999;
            }
            if (val > 1 && val < 6) {
                for (let index = 0; index < val; index++) {
                    let price = this.form.remuneration / val,
                        done_time;
                    var has_dot = price.toString().indexOf(".");
                    if (has_dot != -1) {
                        if (index == 0) {
                            price = this.form.remuneration / 10;
                        } else {
                            price =
                                (this.form.remuneration -
                                    this.form.remuneration / 10) /
                                (val - 1);
                        }
                    }

                    this.$set(new_block, index, {
                        title: "第 " + (index + 1) + " 阶段",
                        remuneration: price,
                        done_time: new Date(
                            new Date().getTime() + 86400000 * (index + 1)
                        )
                    });
                }
                this.form.block = new_block;
            }
            this.local_save();
        },

        //平分价格方法
        pfBlockCountChange() {
            //自动生成分段内容
            let val = this.form.blockCount;
            let new_block = [];
            if (val > 1 && val < 6) {
                for (let index = 0; index < val; index++) {
                    let price = this.form.remuneration / val,
                        done_time;
                    this.$set(new_block, index, {
                        title: this.form.block[index]["title"],
                        remuneration: price,
                        done_time: this.form.block[index]["done_time"]
                    });
                }
                this.form.block = new_block;
            }
            this.local_save();
        },

        //最低首付方法
        sfBlockCountChange() {
            //自动生成分段内容
            let val = this.form.blockCount;
            let new_block = [];
            if (val > 1 && val < 6) {
                for (let index = 0; index < val; index++) {
                    let price = 0;
                    if (index == 0) {
                        price = this.form.remuneration / 10;
                    } else {
                        price =
                            (this.form.remuneration -
                                this.form.remuneration / 10) /
                            (val - 1);
                    }
                    this.$set(new_block, index, {
                        title: this.form.block[index]["title"],
                        remuneration: price,
                        done_time: this.form.block[index]["done_time"]
                    });
                }
                this.form.block = new_block;
            }
            this.local_save();
        },

        //表格内输入价格的变化
        priceInput(val, index) {
            let form = this.form;
            let total = parseFloat(form.remuneration);
            val = parseFloat(val);

            //分段支付价格规则判断
            let len = form.block.length;
            let block = form.block;

            if (index == 0) {
                //第一个输入框
                if (val >= total || total / 10 > val) {
                    this.firstPriceTip = true;
                } else {
                    this.firstPriceTip = false;
                }
            }

            if (len == 2) {
                //分2段
                let price1 = total - val;
                this.$set(block, 1, {
                    title: block[1]["title"],
                    remuneration: price1,
                    done_time: block[1]["done_time"]
                });
            }

            if (len == 3) {
                //分3段
                if (1 == index) {
                    //第二行输入框输入
                    let price2 =
                        total - val - parseFloat(block[0]["remuneration"]);
                    this.$set(block, 2, {
                        title: block[2]["title"],
                        remuneration: price2,
                        done_time: block[2]["done_time"]
                    });
                }
                if (2 == index) {
                    //第二行输入框输入
                    let price3 =
                        total - val - parseFloat(block[0]["remuneration"]);
                    this.$set(block, 1, {
                        title: block[1]["title"],
                        remuneration: price3,
                        done_time: block[1]["done_time"]
                    });
                }
            }

            if (len == 4) {
                //分4段
                if (2 == index) {
                    //第二行输入框输入
                    let price4 =
                        total -
                        parseFloat(block[0]["remuneration"]) -
                        parseFloat(block[1]["remuneration"]) -
                        parseFloat(block[2]["remuneration"]);
                    this.$set(block, 3, {
                        title: block[3]["title"],
                        remuneration: price4,
                        done_time: block[3]["done_time"]
                    });
                }
                if (3 == index) {
                    let price5 =
                        total -
                        parseFloat(block[0]["remuneration"]) -
                        parseFloat(block[1]["remuneration"]) -
                        parseFloat(block[3]["remuneration"]);
                    this.$set(block, 2, {
                        title: block[2]["title"],
                        remuneration: price5,
                        done_time: block[2]["done_time"]
                    });
                }
            }

            if (len == 5) {
                //分5段
                if (3 == index) {
                    //第二行输入框输入
                    let price6 =
                        total -
                        parseFloat(block[0]["remuneration"]) -
                        parseFloat(block[1]["remuneration"]) -
                        parseFloat(block[2]["remuneration"]) -
                        parseFloat(block[3]["remuneration"]);
                    this.$set(block, 4, {
                        title: block[4]["title"],
                        remuneration: price6,
                        done_time: block[4]["done_time"]
                    });
                }
                if (4 == index) {
                    let price7 =
                        total -
                        parseFloat(block[0]["remuneration"]) -
                        parseFloat(block[1]["remuneration"]) -
                        parseFloat(block[2]["remuneration"]) -
                        parseFloat(block[4]["remuneration"]);
                    this.$set(block, 3, {
                        title: block[3]["title"],
                        remuneration: price7,
                        done_time: block[3]["done_time"]
                    });
                }
            }
        },

        //快捷滑动条支付酬金设置
        setPrice() {
            this.priceValue = "";
            this.setPriceDialog = true;
            let price = [];
            this.form.block.forEach((value, key) => {
                if (key == 0) {
                    let currentValue = parseFloat(
                        this.form.block[0]["remuneration"]
                    );
                    price.push(currentValue); //当前阶段价格
                } else if (key != this.form.block.length - 1) {
                    if (key == 1) {
                        let currentValue =
                            parseFloat(this.form.block[0]["remuneration"]) +
                            parseFloat(this.form.block[1]["remuneration"]);
                        price.push(currentValue); //上一阶段价格 + 当前阶段价格
                    }

                    if (key == 2) {
                        let currentValue =
                            parseFloat(this.form.block[0]["remuneration"]) +
                            parseFloat(this.form.block[1]["remuneration"]) +
                            parseFloat(this.form.block[2]["remuneration"]);
                        price.push(currentValue); //上一阶段价格 + 当前阶段价格
                    }

                    if (key == 3) {
                        let currentValue =
                            parseFloat(this.form.block[0]["remuneration"]) +
                            parseFloat(this.form.block[1]["remuneration"]) +
                            parseFloat(this.form.block[2]["remuneration"]) +
                            parseFloat(this.form.block[3]["remuneration"]);
                        price.push(currentValue); //上一阶段价格 + 当前阶段价格
                    }
                }
            });
            price.push(parseFloat(this.form.remuneration));
            this.priceValue = price;
        },

        //保存快捷设置价格
        savePriceSet() {
            this.priceValue.forEach((value, key) => {
                if (key == 0) {
                    this.$set(this.form.block, 0, {
                        title: this.form.block[0]["title"],
                        remuneration: value,
                        done_time: this.form.block[0]["done_time"]
                    });
                } else {
                    this.$set(this.form.block, key, {
                        title: this.form.block[key]["title"],
                        remuneration:
                            parseFloat(value) -
                            parseFloat(this.priceValue[key - 1]),
                        done_time: this.form.block[key]["done_time"]
                    });
                }
            });
            this.setPriceDialog = false;
        }
    },

    created() {
        this.loadDetailData();
    }
};
</script>

<style lang="less" scoped>
@import "../../../styles/todos.less";
</style>

<style lang="less" scoped>
.dotOneStyle,
.dotTwoStyle,
.dotThreeStyle {
    width: 0px;
    height: 0px;
}
.dotFourStyle {
    width: 18px;
    height: 23px;
    position: absolute;
    top: -8px;
    background: linear-gradient(-45deg, transparent 5px, #587ddc 0) right,
        linear-gradient(45deg, transparent 5px, #587ddc 0) left !important;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    border: none;
    opacity: 1;
}
.merge-tooltip {
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translate(-50%, -15px);
}
.el-radio-button__inner {
    padding: 6px 20px;
}
.el-date-editor .el-range-separator {
    width: 10% !important;
}

#invite_info {
    .height_empty {
        width: 100%;
        height: 20px;
    }
    /deep/.el-form-item__content {
        .el-form-item__error {
            height: 40px;
            line-height: 40px;
            top: -15%;
            left: 370px;
        }
    }
    /deep/.el-input {
        width: 210px;
        margin-right: 6px;
        .el-input__suffix {
            right: 180px;
        }
        .el-input__inner {
            text-align: center !important;
            height: 30px !important;
            line-height: 28px !important;
            border: 1px solid #dcdfe6;
        }
    }
    /deep/.is-error {
        .el-input__inner {
            border: 1px solid red;
        }
    }
    /deep/.el-radio-button__inner {
        padding: 6px 20px;
    }
}

.pay_type_class {
    width: 100%;
    clear: both;
    text-align: center;
    padding-top: 40px;
    height: 80px;
    /deep/.el-button {
        width: 200px !important;
        background: #587ddc;
        border: 1px solid #587ddc;
        color: #fff;
        &:hover {
            background: #5c82e2;
            box-shadow: 0px 4px 10px #ccc;
        }
    }
}

.tinfor-item {
    .lab-name {
        font-size: 14px;
    }

    .todo-tit-txt {
        font-size: 14px;
    }
}
</style>


